<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <Header :event="event"/>
      <v-card-title class="headline">Leaderboard Locking for {{event.name}}</v-card-title>

      <v-alert v-if="!eventUtil.isProPlan()" type="info" tile>
        <div class="d-flex flex-row align-content-center">
          <p class="grow mb-0"><router-link class="white--text" :to="{name: 'eventmanagerLicense', params: {id:event.id}}">Upgrade to a PRO plan</router-link> to lock leaderboards.</p>
          <v-btn class="shrink white--text" :to="{name: 'eventmanagerLicense', params: {id:event.id}}">Upgrade</v-btn>
        </div>
      </v-alert>

      <v-card-text>      
        <p>
          Lock or unlock leaderboards for this event.
        </p>
        <p v-if="!event.auto_locked">
          Note: when you lock the leaderboards no further changes are allowed and participants can no longer sync their data with the leaderboards.
        </p>

        <v-alert v-if="event.locked" type="info" tile>
          Leaderboards are locked for this event. No changes to results are allowed.
        </v-alert>
        <v-alert v-else-if="event.auto_locked" type="info" tile>
          <p>Leaderboards are auto locked for this event. No changes to results are allowed.</p>
          <p>Change the Event Settings to unlock the leaderboards</p>
          <v-btn  :to="{name:'eventmanagerEdit', params: {id: event.id}}">Edit</v-btn>
        </v-alert>
        <v-alert v-else-if="event.auto_lock_days" type="info" tile>
          <p>Leaderboards will be automatically locked <strong>{{event.auto_lock_days}} days</strong> after the event is finished.</p>
          <v-btn  :to="{name:'eventmanagerEdit', params: {id: event.id}}">Edit</v-btn>
        </v-alert>
        <v-alert v-else-if="event.auto_lock_on" type="info" tile>
          <p>Leaderboards will be automatically locked on <strong>{{event.auto_lock_on | localDate('L LTS Z')}}</strong>.</p>
          <v-btn  :to="{name:'eventmanagerEdit', params: {id: event.id}}">Edit</v-btn>
        </v-alert>

        <p v-if="event.locked || !event.auto_locked">
          <v-btn v-if="!event.locked" :disabled="!eventUtil.isProPlan()" color="primary" @click="lock">Lock Now</v-btn>
          <v-btn v-if="event.locked" :disabled="!eventUtil.isProPlan()" color="primary" @click="unlock">Unlock Now</v-btn>
        </p>
      </v-card-text>


    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import EventUtil from "@/util/eventUtil";
import siteData from '@/data/site.json'
import Header from './_Header.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "EventLock",
  components: {
    Header,
  },
  props: {
  },
  data() {
    return {
      siteData: siteData,
      tenant: tenant,
      event: null,
    };
  },
  async mounted() {
    await this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });
  },
  methods: {
    async getEvent(id) {
      this.event = (await eventManagerService.get(id)).data;
    },

    async loadData() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },

    async lock() {
      const response = (await eventManagerService.lock(this.event.id)).data;
      await this.loadData();
      this.$helpers.toastResponse(this, response, 'Successfully locked leaderboards.');
    },
    async unlock() {
      const response = (await eventManagerService.unlock(this.event.id)).data;
      await this.loadData();
      this.$helpers.toastResponse(this, response, 'Successfully unlocked leaderboards.');
    },


  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: 'Leaderboard Locking', disabled: true },
      ];
    },
    eventUtil() {
      return new EventUtil(this, this.event);
    },
    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

